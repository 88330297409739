import React, { useEffect, useState, useMemo } from 'react';
import './ImageLibraryModal.css';

const ImageLibraryModal = ({ onClose, onImageSelect }) => {
  const categories = useMemo(() => [
    "All", "Transport", "Fruit", "Animals", "Jobs", "Dinosaurs", "Shapes", 
    "School", "Weather", "Diddy Bots", "Emotions", "Places", "Verbs", 
    "Sports", "Clothing", "Vegetables", "Food & Cutlery", "Home & Garden", 
    "Flags", "Body", "Family", "Toys", "Jigsaws", "Special Days"
  ], []);

  const [selectedCategory, setSelectedCategory] = useState("All");
  const [images, setImages] = useState([]);
  const [imageCache, setImageCache] = useState({}); // Cache to avoid re-fetching
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (imageCache[selectedCategory]) {
      setImages(imageCache[selectedCategory]);
    } else {
      fetchImages(selectedCategory);
    }
  }, [selectedCategory, imageCache]);

  const fetchImages = async (category) => {
    try {
      let images = [];
      if (category === "All") {
        const categoriesToFetch = categories.filter(cat => cat !== "All");
        const results = await Promise.all(
          categoriesToFetch.map(async (cat) => {
            if (imageCache[cat]) return imageCache[cat]; // Use cache if available
            const response = await fetch(`${process.env.PUBLIC_URL}/Icons/${cat}/index.json`);
            const categoryImages = await response.json();
            return categoryImages.map((image) => ({ ...image, category: cat }));
          })
        );
        images = results.flat();
      } else {
        const response = await fetch(`${process.env.PUBLIC_URL}/Icons/${category}/index.json`);
        images = (await response.json()).map((image) => ({ ...image, category }));
      }

      setImageCache(prevCache => ({ ...prevCache, [category]: images })); // Store in cache
      setImages(images);
    } catch (error) {
      console.error("Failed to load images", error);
    }
  };

  const filteredImages = useMemo(() => {
    return images.filter(image => 
      image.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [images, searchTerm]);

  return (
    <div className="image-library-modal-overlay">
      <div className="image-library-modal">
        <div className="image-library-header">
          <h2>Image Library</h2>
          <button onClick={onClose} className="image-library-close-btn" aria-label="Close Modal">
            ×
          </button>
        </div>
        <div className="image-library-search-bar">
          <input
            type="text"
            placeholder="Search images..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="image-library-content">
          <div className="image-library-sidebar">
            <ul>
              {categories.map((category) => (
                <li key={category}>
                  <button
                    className={`image-library-category-btn ${selectedCategory === category ? 'active' : ''}`}
                    onClick={() => setSelectedCategory(category)}
                  >
                    {category}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="image-library-grid">
            {filteredImages.length > 0 ? (
              filteredImages.map((image, index) => (
                <div
                  key={index}
                  className="image-library-card"
                  onClick={() =>
                    onImageSelect({
                      src: `${process.env.PUBLIC_URL}/Icons/${image.category}/${image.file}`,
                      name: image.name,
                      width: 100,
                      height: 100,
                    })
                  }
                >
                  <p className="image-library-name">{image.name}</p>
                  <img
                    src={`${process.env.PUBLIC_URL}/Icons/${image.category}/${image.file}`}
                    alt={image.name}
                    className="image-library-image"
                  />
                </div>
              ))
            ) : (
              <p>No images match your search.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageLibraryModal;
